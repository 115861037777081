]<!-- =========================================================================================
    File Name: Faq.vue
    Description: FAQ Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuesax Admin - VueJS Dashboard Admin Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <div id="faq-page">
        <!-- JUMBOTRON -->
        <div class="faq-jumbotron">
            <div class="faq-jumbotron-content lg:p-32 md:p-24 sm:p-16 p-8 rounded-lg mb-base">
                <h1 class="mb-1 text-white">Barkod Sistemi {{sIl}}?</h1>
                <h2 class="mb-1 text-white">{{sIl}}</h2>
                <h5 class="text-white">Barkod sistemi {{sIl}}, ürünlerin bilgisayarlara bağlantılı optik okuyuculu cihazlar ile tanıtılmasını öngören bir sistemdir.
Birbirine paralel ve değişik kalınlıklardaki çizgi ve boşluklardan oluşan barkod sembolleri, pek çok kişi tarafından sanıldığı gibi ürünle ilgili bilgileri içermez. Bir numerik ya da alfanumerik dizinin çizgi ve boşluklardan oluşan bir alfabeyle ifade edilmesi diye tanımlayabileceğimiz barkod okunduğunda yalnızca bahsi geçen karakterler dizisi bilgisayara gönderilmiş olur.</h5>
<p class="text-white">Barkod Sistemi {{sIl}} Hakkında bilgi edinmek Birkaç dakikanızı ayırın...</p>

                <vs-input placeholder="Search" v-model="faqSearchQuery" icon-pack="feather" icon="icon-search" size="large" class="w-full mt-6" icon-no-border />
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-full md:w-2/5 lg:w-1/4 rounded-lg">
                <vx-card>
                    <h4>Kategori</h4>
                    <ul class="faq-topics mt-4">
                        <li v-for="category in categories" :key="category.id" class="p-2 font-medium flex items-center" @click="faqFilter = category.id">
                            <div class="h-3 w-3 rounded-full mr-2" :class="'bg-' + category.color"></div><span class="cursor-pointer">{{ category.name }}</span>
                        </li>
                    </ul>

                    <br><br>

                    <h4>Personellerimiz</h4>
                    <ul class="faq-supporters mt-4">
                        <li v-for="supporter in supporters" :key="supporter.id" class="mt-4">
                            <div class="flex items-center">
                                <vs-avatar class="mr-3" :src="require(`@/assets/images/portrait/small/${supporter.img}`)" size="35px" />
                                <div class="leading-tight">
                                    <p class="font-semibold">{{ supporter.name }}</p>
                                    <small>{{ supporter.profession }}</small>
                                </div>
                            </div>
                        </li>
                    </ul>
                </vx-card>
            </div>
            <!-- City COL -->
            <div class="vx-col w-full md:w-3/5 lg:w-3/4 mt-12 md:mt-0">
                <vs-collapse accordion type="margin" class="p-0">
                    <vs-collapse-item v-for="(que,index) in filteredCity" class="faq-bg rounded-lg" :class="{'mt-0': !index}" :key="index">
                        <div slot="header"><h5>Barkod Sistemi {{ que.il }} ilçeleri</h5></div>
                        <ul il="ilce">
                        <li v-for="(ilce,index) in que.ilceleri" :key="index">
                                Barkod Sistemi <b>{{ilce}}</b>
                        </li>
                        </ul>
                    </vs-collapse-item>
                </vs-collapse>
                
            </div>

            <!-- FAQ COL -->
            <div class="vx-col w-full md:w-3/5 lg:w-3/4 mt-12 md:mt-0">
                <vs-collapse accordion type="margin" class="p-0">
                    <vs-collapse-item v-for="(que,index) in filteredFaq" class="faq-bg rounded-lg" :class="{'mt-0': !index}" :key="que.id">
                        <div slot="header"><h5>{{ que.question }}</h5></div>
                        <p v-html="que.ans">{{ que.ans }}</p>
                    </vs-collapse-item>
                </vs-collapse>
                
            </div>
        </div>
    </div>
</template>

<script>
import { ililce} from "../../ililce.js"
export default{
    data() {
        return {
            sIl:"Ankara",
            tbIl: ililce,
            faqSearchQuery: '',
            faqFilter: 1,
            categories: [
                {
                    id: 1,
                    name: 'Tümü',
                    color: 'grey',
                },
                {
                    id: 2,
                    name: 'Genel',
                    color: 'primary'
                },
                {
                    id: 3,
                    name: 'Yazılım',
                    color: 'success'
                },
               
                {
                    id: 6,
                    name: 'Yazarkasa Pos',
                    color: 'danger'
                },
                {
                    id: 7,
                    name: 'Fiş Yazıcı',
                    color: 'danger'
                },
                {
                    id: 8,
                    name: 'Barkod Okuyucu',
                    color: 'danger'
                },
                {
                    id: 9,
                    name: 'Barkod Yazıcı',
                    color: 'danger'
                },
                {
                    id: 10,
                    name: 'Terazi',
                    color: 'danger'
                },
                {
                    id: 11,
                    name: 'Baskül',
                    color: 'danger'
                },
                {
                    id: 12,
                    name: 'El Terminali',
                    color: 'danger'
                },
                {
                    id: 13,
                    name: 'Etiket',
                    color: 'danger'
                },
            ],
            faqs: [
                {
                    id: 1,
                    categoryId: 3,
                    question: 'Barkod Programı Nedir?',
                    ans: 'Ankarada Barkod programı barkodlu satış yapan işletmelerin satış ve stok süreçlerini yönetmelerini sağlayan programlardır. Barkod programları market programı, kırtasiye programları, kuruyemiş programları, giyim mağazası programları gibi işletme türüne özel ön ekler almalarına karşın temelde yapmış oldukları şeyler aynıdır. <br> Büyük ölçekli işletmelerin tamamı barkod programları kullanırken KOBİ olarak tanımlanan “Küçük ve Orta Ölçekli İşletme”lerin çok az bir kısmı barkod programları kullanmaktadır. Aslında bu işletmelerde barkod programı kullanmak istemektedirler. Çünkü her işletme sahibi işletmesine giren – çıkan malı, satılan – satılmayan ürünü, cari hesap hareketlerini, hangi firmaya ne kadar borcu olduğunu ve personellerinin ne zaman ne sattığını görmek ister'
                },
                {
                    id: 2,
                    categoryId: 3,
                    question: 'Barkod Programı Neden Kullanılır?',
                    ans: 'Ankarada Günlük perakende satışlar yaptığınız bir mağaza, market ya da süpermarketiniz varsa perakende satışlarınızı satın almadan kasaya dek kolayca takip etmek ve bu sırada etkili bir müşteri yönetimi de yapmak için barkod programı kullanabilirsiniz.'
                },
                {
                    id: 3,
                    categoryId: 3,
                    question: 'Barkod Programı için Nelere İhtiyaç Var?',
                    ans: 'Ankarada Barkod programı kullanmak istiyorsanız sadece 2 şeye ihtiyacınız var. İlki barkod okuyucu bir cihaz ikincisi ise bilgisayar.'
                },
                {
                    id: 4,
                    categoryId: 8,
                    question: 'Barkod Okuyucu Nedir?',
                    ans: 'Ankarada Barkodları okuyarak algıladığı seri numarasını elektronik ortama iletilmesini sağlar. Bu aşamadan sonra iletilen bilgiler (bilgisayarda veya terminalde) çözümlenerek sonuç yansıtılır, böylelikle veri girişi çok daha hızlı ve doğru gerçekleştirilmiş olur. Barkod uygun okuyucu ile okutulduktan sonra, okuyucu siyah ve beyaz çizgileri elektrik sinyallerine dönüştürür. Okuyucunun kod çözücüleri bu sinyalleri çözerek anlayabileceğimiz rakam veya karakterlere çevirir. Bu okuyucuların yaydığı ışın ve barkod çubuklarının oluşturduğu elektronik sinyaller yine bu okuyucular tarafından algılanarak bilgisayarlara rakam veya karakterler olarak aktarılır. Barkodtaki koyu çubuklar ışığı emer, boşluklar ise ışığı geri yansıtır. Böylece elektronik sinyaller oluşur. Barkod okuyucular değişik arabirimlere sahip olabilirler. Klavye, seri port veya usb bağlantılı olabilirler. Bunların yanında bir de radyo frekanslı çalışan barkod okuyucularda bulunmaktadır. Bunlar kablosuzdur ve okutulan barkodu kendi etkinlik alanı içerisinde anında bilgisayara aktarabilirler. Kablolu ve Kablosuz Çeşitleri vardır. <br> Çeşitlerimizi Görmek için <a href="/shop">Mağaza</a> ya geçiş yapın.'
                },
                {
                    id: 5,
                    categoryId: 8,
                    question: 'Barkod Okuyucu Çeşitleri Nelerdir?',
                    ans: 'Ankarada Barkod okuyucu cihazlar tetikli, tetiksiz, el tipi ve masaüstü tip olmak üzere çeşitli hallerde bulunmaktadır. Tetiksiz barkod okuyucu cihazlar ürünü cihaza yaklaştırdığınızda otomatik olarak çalışır. Tetikli barkod okuyucular için ise cihazın üzerindeki tetik ile cihazın çalıştırılması gerekmektedir. El tipi barkod okuyucular elle tutulan barkod okuyuculardır. Masaüstü tipi barkod okuyucular ise masaüstünde veya kasada sabit duran barkod okuyuculardır'
                },
                {
                    id: 6,
                    categoryId: 6,
                    question: 'Yazarkasa Pos Nedir?',
                    ans: 'Ankarada, Yazarkasa, Satış noktasında, nakit depolamak için bir çekmecenin yanı sıra kredi kartı ödemelerini işleme donanımı olan geleneksel, elektronik ödeme işleme sistemidir. Her yazarkasa farklı özelliklere sahiptir, ancak birçoğunda verg ve indirimleri hesaplayabilir, iadeleri ile giderleri işleyebilir, envanter listelerini gözden geçirebilir, günlük satış toplamlarını yazdırabilir, harici bir barkod okuyucu bağlayabilir ve çalışan kullanımını izleyebilirsiniz.'
                },
                {
                    id: 7,
                    categoryId: 7,
                    question: 'Fiş Yazıcı Nedir?',
                    ans: 'Ankarada Genel olarak fiş, hizmet satışını destekleyen bir belgedir. Müşteri tarafından yapılan ödeme tahsilatı üzerine verilir. Fiş yazıcı ise işte bu belgeyi yazan cihazın ismidir. Bu cihazı fiş yazıcı olarak isimlendirmemizin nedeni ise adisyon fişleri de dahil olmak üzere alım satımla ilgili detaylı bilgileri bizlere sunmasından dolayıdır.'
                },
                {
                    id: 8,
                    categoryId: 7,
                    question: 'Fiş Yazıcı Ne İşe Yarar?',
                    ans: 'Ankarada Fiş Yazıcı adisyon fişleri ile normal fişleri basan bir cihaz olduğu için öncelikle en çok market, restoran ve kafe gibi işletmelerde müşterilerin verdikleri sipariş bilgilerinin alınmasında kullanılır. Cihaz hızlı bir şekilde müşterilerin satış sipariş bilgilerini yazılı olarak sunar.'
                },

                {
                    id: 9,
                    categoryId: 9,
                    question: 'Barkod Yazıcı Nedir?',
                    ans: 'Ankarada Barkod basmak için kullanılan cihazlar olarak tanımlanan barkod etiket yazıcı hem termal hem de direk olarak baskı yapabilen cihazlardır. Barkod etiket yazıcı ile basılan barkodların daha uzun ömürlü ve daha dayanıklı olduğu bilinmektedir. Etiket programları kullanılarak barkod etiket yazıcıdan barkod alınabilir'
                },
                {
                    id: 10,
                    categoryId: 9,
                    question: 'Termal Barkod Yazıcı Nedir?',
                    ans: 'Ankarada Barkod yazıcılar diğer nesnelere iliştirilebilen barkod etiketleri üretmek için tasarlanmış bir yazıcıdır. Barkod yazıcı, barkodu doğrudan etikete uygulamak için mürekkep şeritleri kullanırken, termal barkod yazıcıları barkodu etiketin üzerine karalamak için ısı kullanır.'
                },
                {
                    id: 11,
                    categoryId: 10,
                    question: 'Barkodlu Terazi Nedir?',
                    ans: 'Ankarada Tartım ve etiketleme işlemlerinizi hızlandırmak için yeni nesil barkod etiket baskı ölçeği çözümü sunuldu. Barkodlu terazi bu gelişmenin adı. Bu modelin öne çıkan özellikleri doğruluk ve hızlı ödeme deneyimidir. Çoğunlukla marketlerde, fırınlarda, et tezgahlarında ve hazır paket satış noktalarında kullanılıyor.'
                },
                {
                    id: 12,
                    categoryId: 10,
                    question: 'Elektronik Terazi Nedir?',
                    ans: 'Ankarada Elektronik terazi kısaca tartım sonucunu ibre üzerinde değil de bir ekran üzerinde sayısal şekilde bize gösteren cihazlardır. Bu gösterim için elektrik kullanırlar.'
                },
                {
                    id: 13,
                    categoryId: 10,
                    question: 'Dijital Terazi Nedir?',
                    ans: 'Ankarada Ağırlık ölçümlerinde kullanılan loadcell ile kilogramın ölçülüp LCD yardımı ile görüntülenmesini sağlayan cihazın adıdır. Loadcell bir algılayıcıdır. Alüminyum çerçeve üzerine gergin bir şekilde direnç köprüsü kurularak oluşturulan son derece hassas direnç yapısına sahiptir.'
                },
                {
                    id: 14,
                    categoryId: 11,
                    question: 'Baskül Nedir?',
                    ans: 'Ankarada Ağır cisimleri tartmak için kullanılan büyük bir ölçü aletidir. Baskülde yük bir kefeye veya bir çengele asılmayıp, yatay bir tablanın üzerine konur. Ağırlığı büyük olan cisimlerin kütlesini ölçmek için kullanılan alet.'
                },
                {
                    id: 15,
                    categoryId: 11,
                    question: 'Baskül Nasıl Çalışır?',
                    ans: 'Ankarada Baskül Nasıl Çalışır? Tartıya dışarıdan baktığımızda bir platform ve ibreden oluştuğunu görürüz. Üzerine çıkıldığında, platformun aşağı doğru hareket ettiği hissedilir. Ağırlığımızın ölçütü olan bu küçük hareket, tartının içindeki kaldıraç mekanizması tarafından ibreye iletilir.'
                },
                {
                    id: 16,
                    categoryId: 12,
                    question: 'Termal Etiket Nedir?',
                    ans: 'Ankarada Termal etiket, yüzeyinde koruyucu bir tabaka bulunmayan etiket çeşididir. Kağıt üzerine termal lamine edilmesi sonucunda oluşmaktadır. Kullanılan barkod yazıcının baş bölgesindeki ısı ile birlikte termal sıvılaşma özelliği gösterir ve bu şekilde Termal etiketin üzerine baskı alınır.'
                },
                {
                    id: 17,
                    categoryId: 12,
                    question: 'Eko Termal Etiket Nedir?',
                    ans: 'Ankarada Eko termal etiket, maliyet bakımından en uygun barkod etiketi çeşididir ve kısa süreli kullanımlar için uygundur. Eko termal etiketlerin genellikle 3-5 aylık bir kullanım ömrü vardır.'
                },
                {
                    id: 18,
                    categoryId: 12,
                    question: 'Eko Termal Etiketin Kullanım Alanları Nerelerdir?',
                    ans: 'Ankarada Eko Termal Etikete, Barkod yazıcının kafa ısısı ile rahatça daha düşük maliyetlerle etiketler basabilirsiniz. Bu sebepten dolayı genellikle kuru gıda, kargo firmaları, bakliyat, tekstil firmaları, lojistik firmaları, hastaneler ve hızlı tüketimin olduğu sektörler tarafından tercih edilmektedir.'
                },
            ],
            supporters: [
                {
                    id: 1,
                    img: 'avatar-s-1.png',
                    name: 'Ömer Faruk DEMİRYÜREK',
                    profession: 'Genel'
                },
                
                {
                    id: 2,
                    img: 'avatar-s-21.png',
                    name: 'Yusuf TAVŞAN',
                    profession: 'Eğitim Destek'
                },
                
               
            ]
        }
    },
    metaInfo() {
    return {
      title: 'Barkod Sistemi Ankara',
       meta: [
         {property: 'og:type', content: 'website'},
         {property: 'og:title', content: 'Barkod Sistemi Ankara' },
         {property: 'og:description', content: 'Barkod Sistemi Ankara, Market Sistemi Ankara, Restoran Sistemi Ankara, Barkod Yazici Ankara, Barkod Okuyucu Ankara, Yazarkasa Ankara, Dokunmatik Pos Ankara, Termal Fis Yazici Ankara, Terazi ve Baskul Ankara, Etiket ve Rulo Satisi Ankara'},
         {property: 'og:url', content: 'https://www.ceddan.com'+this.$route.path},
         {property: 'og:image', content: 'https://www.ceddan.com/logo.png'},
         {name: 'x-canonical-url',content:'https://www.ceddan.com'+this.$route.path},
         {rel:'canonical',url:'https://www.ceddan.com'+this.$route.path}
       ] 
    }

  },
    computed: {
        filteredCity() {
        const array = this.tbIl;
        const string = this.sIl;
        return array.filter(o =>
                Object.keys(o).some(k => o[k].toString().toLowerCase().includes(string.toLowerCase())));
        },
        filteredFaq() { 
            return this.faqs.filter((faq) => {
                if (this.faqFilter == 1) return (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 2) return faq.categoryId == 2 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 3) return faq.categoryId == 3 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 4) return faq.categoryId == 4 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 5) return faq.categoryId == 5 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 6) return faq.categoryId == 6 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 7) return faq.categoryId == 7 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 8) return faq.categoryId == 8 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 9) return faq.categoryId == 9 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 10) return faq.categoryId == 10 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 11) return faq.categoryId == 11 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 12) return faq.categoryId == 12 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
                else if (this.faqFilter == 13) return faq.categoryId == 13 && (faq.question.toLowerCase().includes(this.faqSearchQuery.toLowerCase()) || faq.ans.toLowerCase().includes(this.faqSearchQuery.toLowerCase()));
            });
        }
    },
    methods: {
        filterByValue(array, string) {
        return array.filter(o =>
        Object.keys(o).some(k => o[k].toString().toLowerCase().includes(string.toLowerCase())));
        }
    },
    components: {
    }
}
</script>

<style lang="scss">
#faq-page {
    .faq-jumbotron-content {
        background-image: url('../../assets/images/pages/faq.jpg');
        background-size: cover;
    }

    .faq-bg {
        background-color: #fff;
    }   
}
</style>